const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/privacy"],
    exact: true,
    component: "Privacy",
  },
  {
    path: ["/shipping"],
    exact: true,
    component: "Shipping",
  },
  {
    path: ["/terms"],
    exact: true,
    component: "Terms",
  },
  {
    path: ["/returns"],
    exact: true,
    component: "Returns",
  },
];

export default routes;

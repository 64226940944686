import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  FooterContainer,
  Language
} from "./styles";
import { JpgIcon } from "../../common/JpgIcon";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:innovanat.comercial@gmail.com">
                <Chat>{t(`footer.email.send`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("footer.policy.title")}</Title>
              <Large to="/privacy" left="true">
                {t("footer.privacy")}
              </Large>
              <Large left="true" to="/shipping">
                {t("footer.shipping")}
              </Large>
              <Large left="true" to="/terms">
                {t("footer.terms")}
              </Large>
              <Large left="true" to="/returns">
                {t("footer.returns")}
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>Trav. de Arteixo 353</Para>
              <Para>Arteixo, A Coruña 15142</Para>
              <Para>Spain</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <JpgIcon src={"banner.png"} width="50%" height="50%" />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
